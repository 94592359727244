import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Container } from 'react-awesome-styled-grid';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import useResizeObserver from 'use-resize-observer/polyfilled';
import InsightsGlobalStyles from '@components/Insights/InsightsGlobalStyles.styled';
import InsightsHead from '@components/Insights/InsightsHead/InsightsHead';
import {
  CommonScripts,
  GridStairs,
  GridTransparent,
  HeaderNavbar,
  InsightsContent,
  InsightsSidebar,
  Reviews,
} from '@components';
import BottomBar from '@components/BottomBar/BottomBar';
import mediaQueries, { breakpoints } from '@utils/media-queries';
import useInsightsPosts, {
  DEFAULT_SORT_OPTION,
  SORT_OPTIONS,
} from '@services/useInsightsPosts';
import {
  headerStairsMobile,
  homeCollectionsStairs,
  insightsGridConfig,
} from '@utils/grid';
import api from '@utils/api';
import HamburgerMenu from '@components/HamburgerMenu/HamburgerMenu';
import { debounce } from '@utils/debounce';
import { MenuContext } from '../components/HamburgerMenu/MenuContext';
import useRemainingItems from '../utils/useRemainingItems';

const {
  xlarge,
  small,
} = mediaQueries;

const Wrapper = styled(Container)`
  position: relative;
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;

  @media ${xlarge} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media ${small} {
    ${({ disableSidePaddings }) => disableSidePaddings && 'padding-right: 0; padding-left: 0;'}
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-top: 52px;

  @media ${xlarge} {
    margin-top: 0;
  }
`;

const Header = styled.header`
  position: relative;
  z-index: 3;
  box-shadow: 0 -8px 44px 0 rgba(0,0,0,0.08);
  background-color: #fff;
`;

const getSrcByType = (data) => {
  switch (data.content_type.medium_type) {
  default:
  case 'url': {
    return data.external_link;
  }
  case 'video': {
    return data.file_video;
  }
  case 'pdf': {
    return data.file_pdf;
  }
  case 'blog': {
    return `https://www.merixstudio.com/blog/${data.blog.slug}`;
  }
  }
};

const ContentItem = ({
  location,
  pageContext: {
    content,
    suggestedItems,
  },
}) => {
  const {
    menu_items: menuItems,
    footer_image: footerImage,
    social_media_icons: socialItems,
    chat_interaction_id: chatInteractionId,
    cta_text: chatInteractionText,
    clutch,
    reviews,
    id,
  } = content;

  const parsedClutch = {
    ...clutch,
    firstText: clutch.first_text,
    reviewsUrl: clutch.reviews_url,
    secondText: clutch.second_text,
  };

  const contentData = {
    src: getSrcByType(content),
    title: content.title,
    type: content.content_type.medium_type,
    video: {
      desc: content.video_description,
      dur: content.video_duration,
      fileUrl: content.file_video,
      thumb: content.video_thumbnail,
      title: content.video_title,
      upDate: content.video_upload_date,
    } || null,
  };

  const meta = {
    description: content.meta_description,
    og_description: content.meta_description,
    og_image: content.image,
    title: content.meta_title,
  };

  const parsedQueryFilters = typeof window !== 'undefined' && qs.parse(window.location.search);
  const initialOrder = SORT_OPTIONS.includes(parsedQueryFilters?.ordering) ?
    parsedQueryFilters?.ordering :
    DEFAULT_SORT_OPTION;

  const initialFilters = {
    exclude_id: id,
    industry: parsedQueryFilters?.industry,
    ordering: initialOrder,
    persona: parsedQueryFilters?.persona,
    technology: parsedQueryFilters?.technology,
    theme: parsedQueryFilters?.theme,
    topic: parsedQueryFilters?.topic,
    type: parsedQueryFilters?.type,
  };

  const {
    ref, width: windowWidth = 0,
  } = useResizeObserver();

  const pageSize = 9;
  const [
    postsState,
    fetchNextPage,
  ] = useInsightsPosts(initialFilters, { pageSize });

  const [
    visibleSuggestedItems,
    visibleSuggestedItemsCount,
  ] = useRemainingItems(suggestedItems, content.id);
  const [
    visibleItems,
    visibleItemsCount,
  ] = useRemainingItems(postsState.data, content.id);

  useEffect(() => {
    api.post('/content-insights/increase-content-visit-count', {
      content_id: id,
    });
  }, []);

  const shouldDisableSidePaddings = contentData.type === 'pdf' || contentData.type === 'blog';

  const shouldDisableIndexing = contentData.type === 'link' || contentData.type === 'blog';

  const checkScrollYAmount = () => window.scrollY >= 100;

  const [
    resizeClass,
    setResizeClass,
  ] = useState('');

  const addResizeClass = () => setResizeClass('resizing');
  const removeResizeClass = debounce(() => {
    setResizeClass('');
  }, 1000);

  const handleResize = () => {
    addResizeClass();
    removeResizeClass();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setDefaultOverflowValue = useCallback(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
  }, []);

  const [
    revealMenuButton,
    setRevealMenuButton,
  ] = useState(false);

  const [
    isOpenedFromNavigation,
    setOpenedFromNavigation,
  ] = useState(false);

  const showMenuBtn = useCallback(() => {
    if (isOpenedFromNavigation) {
      setRevealMenuButton(checkScrollYAmount());
      setDefaultOverflowValue();
    } else {
      setRevealMenuButton(checkScrollYAmount());
      setDefaultOverflowValue();
    }
  }, [setDefaultOverflowValue]);

  useEffect(() => {
    const onScroll = () => showMenuBtn();

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [showMenuBtn]);

  const [
    icon,
    setIcon,
  ] = useState('menu');
  const switchIcon = () => {
    setIcon((last) => (last === 'close' ? 'menu' : 'close'));
  };

  const [
    menuVisible,
    setMenuVisible,
  ] = useState(false);
  const [
    toggleMenu,
    setToggleMenu,
  ] = useState(false);

  const switchToggle = () => {
    setTimeout(() => {
      setToggleMenu(() => !menuVisible);
    }, 100);
  };

  const [
    isExpanded,
    setExpanded,
  ] = useState(false);

  const [
    activeElement,
    setActiveElement,
  ] = useState('');

  const toggleClick = (attr) => {
    setExpanded(true);
    setActiveElement(attr);
  };

  const closeExpand = () => {
    if (checkScrollYAmount()) {
      setRevealMenuButton(true);
      setOpenedFromNavigation(false);
    }
    setExpanded(false);
    setActiveElement(null);
  };

  const setOverlayValue = () => {
    document.getElementsByTagName('html')[0].style.overflowY = toggleMenu ? 'auto' : 'hidden';
  };

  const handleMenuClick = () => {
    if (!checkScrollYAmount()) {
      setRevealMenuButton(false);
    } else {
      setRevealMenuButton(true);
    }
    setMenuVisible(!menuVisible);
    switchIcon();
    switchToggle();
    setOverlayValue();
  };

  const manageMenuButtonState = () => {
    if (checkScrollYAmount()) {
      setRevealMenuButton(false);
      setOpenedFromNavigation(false);
    }
    closeExpand();
    handleMenuClick();
  };

  return (
    <MenuContext.Provider
      value={{
        isExpanded,
        isVisible: toggleMenu,
        setExpanded,
      }}
    >
      <ThemeProvider theme={{ awesomegrid: insightsGridConfig }}>
        <CommonScripts />
        <InsightsGlobalStyles />
        {meta && <InsightsHead meta={meta} />}
        {shouldDisableIndexing && (
          <Helmet>
            <meta
              name="robots"
              content="noindex"
            />
          </Helmet>
        )}
        <Header ref={ref}>
          <HeaderNavbar
            menuItems={menuItems}
            socialItems={socialItems}
            visible={toggleMenu}
            openMenu={handleMenuClick}
            setRevealMenuButton={setRevealMenuButton}
            revealMenuButton={revealMenuButton}
            setOpenedFromNavigation={setOpenedFromNavigation}
          />
        </Header>
        <HamburgerMenu
          activeElement={activeElement}
          closeExpand={closeExpand}
          icon={icon}
          isExpanded={isExpanded}
          manageMenuButtonState={manageMenuButtonState}
          showMenuBtn={revealMenuButton}
          toggleClick={toggleClick}
          toggleMenu={toggleMenu}
          chatId={chatInteractionId}
        />
        <Wrapper disableSidePaddings={shouldDisableSidePaddings}>
          <GridTransparent />
          <InsightsSidebar
            prevPath={location.state?.prevPath}
            allItemsCount={postsState.allItemsCount}
            isFetching={postsState.isFetching}
            items={visibleItems}
            itemsCount={visibleItemsCount}
            hasMorePosts={postsState.hasNextPage}
            hasPostsError={!!postsState.error}
            data={content}
            fetchMorePosts={fetchNextPage}
            suggestedItems={visibleSuggestedItems}
            suggestedItemsCount={visibleSuggestedItemsCount}
            chatInteractionId={chatInteractionId}
            chatInteractionText={chatInteractionText}
            filters={initialFilters}
            isMobileWidth={windowWidth <= breakpoints.large}
          />

          <ContentContainer>
            <InsightsContent
              data={contentData}
            />
          </ContentContainer>
        </Wrapper>

        {windowWidth > breakpoints.large && (
          <>
            <GridStairs
              colorDown="white"
              offsets={windowWidth > breakpoints.small ? homeCollectionsStairs : headerStairsMobile}
            />

            <Reviews
              reviews={reviews}
              image={footerImage}
              windowWidth={windowWidth}
            />
            <BottomBar clutch={parsedClutch} />
          </>
        )}
      </ThemeProvider>
    </MenuContext.Provider>
  );
};

ContentItem.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
  pageContext: PropTypes.shape({
    content: PropTypes.object,
    stepSlug: PropTypes.string,
    suggestedItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })),
  }).isRequired,
};

export default ContentItem;
